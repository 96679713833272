/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import photo from '../../images/Photo.svg'

export const ReviewItem = () => {
	return (
		<section className='reviews'>
			<div className='reviews-title'>
				<div>What people say about us</div>
			</div>

			<div className='reviews-cards'>
				<div className='review-card'>
					<img src={photo} />
				</div>
				<div className='review-card without-photo'>
					<div className='review-stars-block'>
						<div className='review-stars'>★★★★★</div>
						<div className='review-text'>01/04</div>
					</div>
					<div className='review-card-content'>
						<div className='review-card-title'>Very helpful service!</div>
						<div className='review-card-description'>
							My husband and I moved out of a rented apartment, the owners did
							not want to break the contract. Nightmare hosts got caught. Needed
							money urgently hob. Night in the yard! At VivatPay Husband sent a
							request, threw off fast money to his card, we are their owners,
							yes, they moved out.
						</div>
					</div>
				</div>
				<div className='review-card without-photo'>
					<div className='review-stars-block'>
						<div className='review-stars'>★★★★★</div>
						<div className='review-text'>02/04</div>
					</div>
					<div className='review-card-content'>
						<div className='review-card-title'>Great service!</div>
						<div className='review-card-description'>
							I always use the services of your company. It's a pleasure to work
							with you. I am completely satisfied with the level of service.
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
