import React from 'react'
import LoanCalculator from '../../components/LoanCalculator.js/LoanCalculator'
import './About.css'
export const About = () => {
	return (
		<>
			<section className='page-background'>
				<div className="content-container">
				<div className='page-container'>
					<div className='about-left'>
						<div className='page-title'>About us</div>
						<div className='about-text'>
							<p>
								VivatPay is a service for providing loans electronically, which
								does not require visiting the office and providing unnecessary
								documents. Completing the application takes about 7 minutes.
							</p>
							<p>
								If the application is approved, the money will be transferred to
								your account or bank card. We offer our services to everyone who
								needs to get a loan quickly, without collateral, without lengthy
								registration procedures and checks.
							</p>
							<p>
								Loyalty is the hallmark of our Company, and we are constantly
								working on the attractiveness of our product. To confirm this,
								we constantly offer our regular Customers various promotions and
								loyalty progrania.
							</p>
						</div>
					</div>
					<div className='about-right'>
						<LoanCalculator width={300} />
					</div>
				</div>
				</div>
			</section>
		</>
	)
}
