import React from 'react'
import './Home.css'


const Card = ({ imgSrc, title, description }) => {
	return (
		<div className='card'>
			<img src={imgSrc} alt='' />
			<div className='card-name'>{title}</div>
			<p className='card-description'>{description}</p>
		</div>
	)
}

export default Card
