import React from 'react'
import LoanCalculator from '../../components/LoanCalculator.js/LoanCalculator'
import postpone from '../../images/postpone.svg'
import './Postpone.css'
export const HowToPostpone = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='page-container'>
						<div className='postpone-left'>
							<div className='page-title'>How to postpone loan payments</div>
							<div className='postpone-img'>
								<img className='img' src={postpone} alt='' />
							</div>

							<div className='text-content'>
								<p>
									If you have any questions regarding the Privacy Policy or you
									would like to obtain access and/or make corrections to your
									Personal Information or cancel your Personal Account, please
									contact our Data Protection Officer at{' '}
									<a href='mailto:customer@lendplus.ke'>vivatpay@gmail.com</a>.
								</p>

								<p>
									You have to understand that if your account will be canceled:
								</p>

								<ol>
									<li>You will log out of this account.</li>
									<li>
										You will not be able to log in again, and all retained
										information will be cleared and cannot be retrieved.
									</li>
								</ol>

								<p style={{ margin: '20px 0' }}>
									Specifically includes the following information:
								</p>

								<ul>
									<li>Personal information</li>
									<li>Loan records, coupon usage record</li>
									<li>Loan amount obtained</li>
								</ul>

								<p style={{ margin: '20px 0' }}>
									3. You will never be able to get a loan from VivatPay again.
								</p>
							</div>
						</div>
						<div className='postpone-right'>
							<LoanCalculator width={300} />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
