import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton, Paper, Slider, Typography } from '@mui/material'
import React, { useState } from 'react'
import '../../pages/Home/Home.css' // Если есть стили в этом файле
import { BtnApply } from '../BtnApply/BtnApply' // Ваш компонент для кнопки "Apply for a loan"

const LoanCalculator = ({ width }) => {
	const [loanAmount, setLoanAmount] = useState(250)

	const handleSliderChange = (event, newValue) => {
		setLoanAmount(newValue)
	}

	const incrementAmount = () => {
		setLoanAmount(prevAmount => Math.min(prevAmount + 10, 400))
	}

	const decrementAmount = () => {
		setLoanAmount(prevAmount => Math.max(prevAmount - 10, 50))
	}

	return (
		<Paper
			elevation={3}
			sx={{
				padding: 3,
				borderRadius: 3,
				margin: 'auto',
				width: width ? width : 400,
			}}
		>
			<Typography variant='h6' align='left' gutterBottom>
				Loan amount
			</Typography>
			<Box display='flex' alignItems='center' mt={2} flexDirection='column'>
				<Typography sx={{ mb: 2, fontSize: '24px', fontWeight: 'bold' }}>
					{loanAmount} EUR
				</Typography>
				<Box
					display='flex'
					alignItems='center'
					width='100%'
					position='relative'
				>
					<IconButton
						onClick={decrementAmount}
						disabled={loanAmount <= 50}
						sx={{
							color: '#6A1B9A',
							position: 'absolute',
							left: '-20px',
							marginTop: '-20px',
						}}
					>
						<RemoveIcon />
					</IconButton>
					<Slider
						value={loanAmount}
						onChange={handleSliderChange}
						min={50}
						max={400}
						step={10}
						marks={[
							{ value: 50, label: '50 EUR' },
							{ value: 400, label: '400 EUR' },
						]}
						sx={{
							flexGrow: 1,
							mx: 5,
							color: 'rgba(105, 115, 149, 1)',
							'.MuiSlider-markLabel': {
								color: 'rgba(105, 115, 149, 1)',
								fontSize: '12px',
							},
						}}
					/>
					<IconButton
						onClick={incrementAmount}
						disabled={loanAmount >= 400}
						sx={{
							color: '#6A1B9A',
							position: 'absolute',
							right: '-20px',
							marginTop: '-20px',
						}}
					>
						<AddIcon />
					</IconButton>
				</Box>
			</Box>
			<Box mt={4} textAlign='center'>
				<BtnApply />
			</Box>
		</Paper>
	)
}

export default LoanCalculator
