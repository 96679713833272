import React from 'react'

export const Description = () => {
	return (
		<section className='description-block'>
			<div className='description-title'>
				Information about the owner of the website providing payday loans online
			</div>

			<div className='description-text'>
				Our main goal is to make the process of obtaining microcredit easier and
				easier for you than ever before! With the help of advanced technologies,
				we have simplified the process of obtaining a microcredit, and every day
				we continue to make it even faster and more convenient. With us, you can
				get a microloan without leaving your home, spending only 5 minutes on
				it. Just a few simple steps - and you are at the goal! Well, if you have
				any questions, our support team will be happy to help you.
			</div>
		</section>
	)
}
