import React from 'react'
import './Home.css'

const EasyStep = ({ stepNumber, title, description }) => {
	return (
		<div className='easy-block-card'>
			<div className='circle'>{stepNumber}</div>
			<div className='easy-block-card-title'>{title}</div>
			<div className='easy-block-card-description'>{description}</div>
		</div>
	)
}

export default EasyStep
