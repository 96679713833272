import React from 'react'
import { Faq } from '../../components/FaqAccordion/Faq'
import { faqDataInPage } from '../../components/FaqAccordion/faqData'
import LoanCalculator from '../../components/LoanCalculator.js/LoanCalculator'
import './Faq.css'
export const FaqPages = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='page-container'>
						<div className='faq-left'>
							<div className='page-title' style={{ marginBottom: '20px' }}>
								FAQ
							</div>

							<Faq faqs={faqDataInPage} bgc='white' />
						</div>
						<div className='faq-right'>
							<LoanCalculator width={300} />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
