import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import email from '../../images/Message_alt_fill.svg'
import phone from '../../images/Phone_fill.svg'
import time from '../../images/Time_fill.svg'
import './Footer.css'
export const Footer = () => {
	const location = useLocation()
	const showNav = !location.pathname.includes('/register')
	return (
		<div className='footer'>
			<div className='footer-content'>
				<div className='footer-block'>
					<div className='footer-about'>
						<div>
							<img src={phone} alt='' />
						</div>
						<div className='footer-about-text'> +370 692 10831</div>
					</div>

					<div className='footer-about'>
						<div>
							<img src={email} alt='' />
						</div>
						<div className='footer-about-text'>vivatpay@gmail.com</div>
					</div>

					<div className='footer-about'>
						<div>
							<img src={time} alt='' />
						</div>
						<div className='footer-about-text'>Monday-Friday: 8AM – 5PM</div>
					</div>
				</div>
				{showNav && (
					<div className='footer-block'>
						<Link className='footer-text-pages' to='/borrow'>
							How to borrow
						</Link>
						<Link className='footer-text-pages' to='/postpone'>
							How to postpone
						</Link>
						<Link className='footer-text-pages' to='faq'>
							FAQ
						</Link>
						<Link className='footer-text-pages' to='about'>
							About us
						</Link>
						<Link className='footer-text-pages' to='contact'>
							Contact us
						</Link>
						<Link className='footer-text-pages' to='cancellation'>
							Account Cancellation
						</Link>
					</div>
				)}
				<div className='footer-block'>
					<div className='footer-text-social-text'>
						VivatPay UAB is a company duly incorporated and registered under the
						laws of the Republic of Lithuania, with a company registration no.
						306455100
					</div>
					<div className='policy-block'>
						<div className='footer-text-social-policy'><Link className='footer-text-pages' to='/privacy-policy'>Privacy Policy</Link></div>
						<div className='footer-text-social-policy'><Link className='footer-text-pages' to='/terms-and-conditions'>Terms & Conditions</Link></div>
					</div>
				</div>
			</div>
		</div>
	)
}
