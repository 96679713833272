import React from 'react'
import './Cancellation.css'
export const Cancellation = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='page-container'>
						<div className='left'>
							<div className='page-title'>Account Cancellation</div>
							<div className='cancellation-card'>
								<div className='cancellation-address'>
									VivatPay UAB is a company duly incorporated and registered
									under the laws of the Republic of Lithuania, with a company
									registration no. 306455100
								</div>
								<div className='cancellation-phone'>+370 692 10831</div>
							</div>
							<div className='privacy-policy'>
								<p>
									If you have any questions regarding Privacy Policy or you
									would like to obtain access and/or make corrections to your
									Personal Information/or cancellation of Personal Account,
									please contact our Data Protection Officer at{' '}
									<a href='mailto:customer@lendplus.ke'>vivatpay@gmail.com</a>.
								</p>

								<p>You have to understand that if your account is canceled:</p>

								<ol>
									<li>You will log out of this account.</li>
									<li>
										You will not be able to log in again, and all retained
										information will be cleared and cannot be retrieved.
									</li>
								</ol>

								<p>Specifically includes the following information:</p>

								<ul>
									<li>Personal information</li>
									<li>Loan records, coupon usage record</li>
									<li>Loan amount obtained</li>
								</ul>

								<p>You will never be able to get a loan from VivatPay again.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
