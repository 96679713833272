import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import login from '../../images/ButtonSecondary.svg'
import logo from '../../images/logoVivat.svg'
import './Header.css'

export const Header = () => {
	const location = useLocation()
	const currentPath = location.pathname

	const isActive = path => currentPath === path

	return (
		<header className='header'>
			<div className='header-content'>
				<Link to='/'>
					<img src={logo} alt='Logo' />
				</Link>

				{!currentPath.includes('/register') && (
					<nav className='nav'>
						<Link
							className={`nav-link ${isActive('/borrow') ? 'active' : ''}`}
							to='/borrow'
						>
							How to borrow
						</Link>
						<Link
							className={`nav-link ${isActive('/postpone') ? 'active' : ''}`}
							to='/postpone'
						>
							How to postpone
						</Link>
						<Link
							className={`nav-link ${isActive('/faq') ? 'active' : ''}`}
							to='/faq'
						>
							FAQ
						</Link>
						<Link
							className={`nav-link ${isActive('/about') ? 'active' : ''}`}
							to='/about'
						>
							About us
						</Link>
						<Link
							className={`nav-link ${isActive('/contact') ? 'active' : ''}`}
							to='/contact'
						>
							Contact us
						</Link>
						<Link
							className={`nav-link ${
								isActive('/cancellation') ? 'active' : ''
							}`}
							to='/cancellation'
						>
							Account Cancellation
						</Link>
					</nav>
				)}

				<div className='contact'>
					<span className='phone-number'>+370 692 10831</span>
					{!currentPath.includes('/register') && (
						<Link to='/register'>
							<img src={login} alt='Login' className='login-button' />
						</Link>
					)}
				</div>
			</div>
		</header>
	)
}
