import React from 'react'
import email from '../../images/email_contact.svg'
import phone from '../../images/phone_contact.svg'
import time from '../../images/time_contact.svg'
import './Contact.css'

export const ContactUs = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='page-container'>
						<div className='contact-left'>
							<div className='page-title'>Contact us</div>

							<div className='contact-flex'>
								<div className='contact-block'>
									<img src={phone} alt='' />
									<p className='placeholder'>Support service</p>
									<p className='contact-data'>+370 692 10831</p>
								</div>
								<div className='contact-block'>
									<img src={email} alt='' />
									<p className='placeholder'>Mail address</p>
									<p className='contact-data'>vivatpay@gmail.com</p>
								</div>
								<div className='contact-block'>
									<img src={time} alt='' />
									<p className='placeholder'>Schedule</p>
									<p className='contact-data'>Monday - Friday 8AM – 5PM</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
