export const faqDataInHome = [
	{
		question: 'What is VivatPay?',
		answer:
			'VivatPay is a payment service provider that offers quick and easy online payment solutions.',
	},
	{
		question: 'How do I apply for a loan?',
		answer:
			'To apply for a loan, fill out the application form on our website with your personal and financial information.',
	},
	{
		question: 'What is the process for getting a loan?',
		answer:
			'The process includes applying online, receiving a decision, and then receiving the funds transferred to your account.',
	},
]

export const faqDataInPage = [
	{
		question: 'How do you check the payment amount?',
		answer:
			'You can easily check all loan details on your personal account or contract us via phone number +370 692 10831 from 8AM - 5PM Monday-Friday',
	},
	{
		question: 'What if i pay my loan late?',
		answer:
			'We encourage you to pay off your loan on/immediately before the due date to maintain your good credit history. In the event that you pay late, a late fee will apply. If you are experiencing financial difficulties and can`t pay your loan, please contact us at +370 692 10831 8AM-5PM Monday-Friday to find the optimal payment solution.',
	},
	{
		question: 'Is VivatPay legit?',
		answer:
			'We operate in strict accordance with the laws of the European Union. Registered company details: VivatPay UAB is a company duly incorporated and registered under the laws of the Republic of Lithuania, with company registration no. 306455100.',
	},
	{
		question: 'How long does VivatPay take to pay out?',
		answer:
			'During business hours (specified on the website), processing the request takes from 15 minutes to 2 hours. However, the process of receiving money may be delayed, since banks do not send money on weekends and outside working hours. In this case, we can offer the transfer of funds to the eWallet (transfer instantly, regardless of the day of the week). To do this, you need to select a Wallet when submitting an application.',
	},
	{
		question: 'What is the limit of VivatPay loan?',
		answer: 'We approve amounts up to 400 EUR. This amount is available only to repeat customers',
	},
	{
		question: 'What is the email address for VivatPay?',
		answer: 'Our email  vivatpay@gmail.com. You can also call us at +370 692 10831 or write a message in the online chat.',
	},
]
