import React from 'react'
import { Link } from 'react-router-dom'
import './Terms.css'

export const Term = () => {
	return (
		<section className='page-background'>
			<div className='content-container'>
				<div className='terms-of-use'>
					<h2>1. Introduction</h2>
					<p>
						VivatPay, UAB (hereinafter referred to as the “Company”, “Financial
						Company”, “we”, “us” or “our”) values your trust and is committed to
						ensure proper provision of services to you. Please read these Terms
						of use carefully before submission of the{' '}
						<Link to='/register'> Authorisation Form</Link> and using our
						services. By submitting the{' '}
						<Link to='/register'> Authorisation Form</Link> and registration on
						the Company’s Website, you agree that you have read, understood and
						accepted all terms and conditions hereof.
					</p>

					<h2>2. Terms and Definitions</h2>
					<p>
						Information Technology Service Agreement or Service Agreement,
						regardless of its specific name (hereinafter the “Agreement”) –a
						written agreement on cooperation between the Company and the User
						signed by and agreed between the Parties based on and taking into
						account this Terms of Use unless otherwise specified in the
						Agreement.
					</p>
					<p>
						User – a legal entity, individual entrepreneur or an individual to
						whom the Financial Company provides the service of performing a
						Money Transfer. Users or Clients are Payers, Senders and Recipients.
						At the same time, an individual, an individual entrepreneur and/or
						legal entity are considered as participants in legal relations
						arising between Payers, Senders and Recipients.
					</p>
					<p>
						Merchant – a legal entity or a natural person carrying out
						activities, in accordance with the Regulatory Laws, ensuring the
						execution and fulfillment of the Order.
					</p>
					<p>
						Service – services providing information and technological
						interaction between the beneficiary (Merchant) – individual
						entrepreneur, legal entity and payment service provider (Bank) when
						the latter transfers money in favor of the beneficiary on payments
						accepted from payers.
					</p>
					<p>
						Transaction – an operation of accepting payment or disbursement of
						funds.
					</p>
					<p>Order – Goods/Services to be delivered by Merchant.</p>
					<p>
						Acquiring Bank – a bank that transfers funds from the Payer’s EPS to
						the Recipient’s current account.
					</p>
					<p>
						Payment – a transfer of funds from the Merchant in favor of the
						Payee.
					</p>
					<p>
						Payment system – a system for performing payment transactions with
						formal and standardized arrangements and general rules for
						processing, clearing and/or settlement between participants of the
						payment system.
					</p>
					<p>
						Payment Instruction – a request to carry out a transaction for
						acceptance of payment or payment against fulfillment of the Order.
					</p>
					<p>
						Payment Acceptance – a transfer of funds from the Sender in favor of
						the Merchant.
					</p>
					<p>
						Business day – a part of the Financial Company’s business day during
						which the necessary information is received from the Clients for the
						Transfer of Funds and, if technically possible and technically
						feasible, is processed, transferred and executed.
					</p>
					<p>
						Recipient – a legal entity to whose account the amount of the
						payment transaction is credited.
					</p>
					<p>
						Payer – an individual from whose account (using the Card or its
						details) a payment transaction is initiated on the basis of a
						payment instruction.
					</p>
					<p>
						Sender – a natural person or a legal entity that has placed the
						Order using the Payment Acceptance Service.
					</p>
					<p>
						Funds transfer – a payment service during which a certain amount of
						funds is transferred in non-cash form in the national currency for
						the purpose of crediting it to the Recipient’s account.
					</p>
					<p>
						Financial Company Service and/or VivatPay System – a set of
						software, technical and organizational measures and/or API of the
						Financial Company, which is intended to provide the Payer with the
						opportunity to use electronic means of payment to initiate the
						transfer of funds from their own account to the Recipient’s account,
						as well as to receive technical services that accompanying the
						provision of money transfer services.
					</p>
					<p>
						Electronic payment instrument (EMI) – a payment instrument
						implemented on any medium containing electronic data necessary to
						initiate a payment transaction and/or other transactions specified
						in the contract with the issuer.
					</p>
					<p>
						Means and Resources – an organizational, informational,
						technological, material, intellectual, labor, financial, legal and
						other means and resources, including (but not limited to): Internet
						site, premises and equipment, information system, personnel, funds,
						registration, licenses, contractual relations, rules, standards and
						other.
					</p>
					<p>
						<Link to='/register'> Authorisation Form</Link> – is an online
						application posted by the Client on the Company’s Website with
						Company’s services including but not limited to with the terms of
						their provision.
					</p>

					<h2>3. Description of Services</h2>
					<p>
						Under the Agreement, the User shall entrust and the Financial
						Company shall provide a financial payment service for the Transfer
						of Funds to the Recipient under the terms of the Agreement. The
						Financial Company does not sell (realize) goods, works, services
						sold (realized) by the Recipients. Legal relations between the User
						and the Recipient shall arise, change and terminate exclusively
						between the User and the Recipient. The Financial Company is solely
						responsible for accepting funds from the User and transferring the
						received funds to the Recipient. All essential terms and conditions
						for the provision of services by the Company are set out in the
						Agreement executed between you and the Company. In case of
						discrepancies between these Terms of Use and the Agreement, the
						Agreement shall prevail.
					</p>

					<h2>4. The Procedure for Providing the Services</h2>
					<ul>
						<li>Merchant’s connection to the VivatPay System;</li>
						<li>Carrying out settlements;</li>
						<li>Disconnection of Merchant from the VivatPay System.</li>
					</ul>

					<h2>5. Procedure of Merchant’s Connection to the VivatPay System</h2>
					<ul>
						<li>Application Registration;</li>
						<li>Access to the Personal Account;</li>
						<li>Filling in the form;</li>
						<li>Collection of the documents;</li>
						<li>Verification;</li>
						<li>Joining the Agreement;</li>
						<li>Integration;</li>
						<li>Setting up transaction processing parameters;</li>
						<li>Testing;</li>
						<li>Activation of “live mode”.</li>
					</ul>

					<h2>6. Provision of Payment Initiation Services</h2>
					<p>
						We offer payment initiation services in line with the regulations
						outlined in the Payment Law of the Republic of Lithuania and the
						established standards of Payment initiation set by the Bank of
						Lithuania. Personal data provided for this purpose will be processed
						in accordance with our{' '}
						<Link to='/privacy-policy'> Privacy Policy</Link>, General Data
						Protection Regulation 2016/679, and statutory requirements of
						respective laws of the Republic of Lithuania in order to facilitate
						the payment initiation services in a secure manner.
					</p>

					<p>
						Our processing of your personal data is contingent only after you
						have read and accepted our{' '}
						<Link to='/privacy-policy'> Privacy Policy</Link>. You cannot submit
						the
						<Link to='/register'> Authorisation Form</Link> on our Website
						without providing prior approval to the{' '}
						<Link to='/privacy-policy'>
							{' '}
							<Link to='/privacy-policy'> Privacy Policy</Link>
						</Link>
						. This data processing is crucial for identification, payment
						initiation, payment authentication, and confirmation of
						transactions. Please be assured that we cannot proceed with payment
						initiation services without your consent.
					</p>

					<p>
						The list of personal data and the procedure for their collection and
						data processing is set out in the{' '}
						<Link to='/privacy-policy'> Privacy Policy</Link>. Please be advised
						that following the completion of a payment, both you and the
						merchant from whom you acquired goods or services will receive
						notification of the payment status via email or via SMS to the
						telephone number indicated in the{' '}
						<Link to='/register'> Authorisation Form</Link> on our Website.
					</p>

					<h2>7. Responsibility of Parties</h2>
					<p>
						The Financial Company shall be liable to the User for the Transfer
						of Funds in accordance with the Law of the Republic of Lithuania and
						the terms of the Agreement.
					</p>

					<p>
						The Financial Company is not responsible for the quality of public
						communication channels, as well as data transmission networks,
						including the global Internet, which are used to access the services
						and facilities of the Financial Company.
					</p>

					<p>
						The Financial Company is not responsible for the quality of goods,
						works, services of the Recipients, and is not responsible for the
						Recipient’s obligations to the Payer.
					</p>

					<p>
						The Financial Company is not responsible for and does not control
						the legality of transactions between the Payer and the Recipient,
						their terms, as well as the fact and consequences of their
						conclusion, execution, and/or termination of contracts, including in
						terms of refund of payment under such contracts. The Financial
						Company does not consider claims of the User concerning
						non-performance (improper fulfillment) by the Recipient or the Payer
						of their obligations under the Agreement, including obligations to
						transfer goods, provide services/perform work.
					</p>

					<p>
						The Financial Company is liable only for documented actual loss. In
						any case, the maximum amount of liability for documented actual loss
						may not exceed the amount of funds received from the User (Payer) as
						payment for the Funds Transfer services.
					</p>

					<p>
						The Financial Company shall not be liable for any damage incurred as
						a result of errors, omissions, interruptions, defects, and delays in
						operation or data transmission.
					</p>

					<p>
						The User is responsible for the compliance of the information
						specified by him/her when initiating the Funds Transfer with the
						essence of the transaction under which this Transfer is carried out
						in accordance with the legislation of the Republic of Lithuania.
					</p>

					<p>
						The User of the Financial Company’s Service undertakes to
						independently take all necessary measures to maintain
						confidentiality, prevent unauthorized use, and protect their
						personal data, data of electronic means of payment (payment cards)
						belonging to them from unauthorized access by third parties.
					</p>

					<h2>8. Termination and Suspension of Services</h2>
					<p>
						In addition to the termination terms indicated in the Agreement
						executed between the Company and the User, the Company may
						unilaterally stop providing services without prior notice in such
						cases:
					</p>
					<ul>
						<li>Suspending the provision of Payment Initiation Services;</li>
						<li>Restricting or suspending access to the VivatPay System;</li>
						<li>
							Reversing a payment transaction and returning funds to the Payer’s
							account.
						</li>
					</ul>

					<p>
						The Company may implement its right for termination and suspension
						of the Services outlined in this clause 8 under the following
						circumstances:
					</p>
					<ul>
						<li>
							In the event of a material breach of these Terms of Use and the
							Agreement by you;
						</li>
						<li>
							If suspicion arises that you are involved in Money Laundering or
							Terrorist Financing activities, or if VivatPay System or our
							Services are used or intended to be used for illegal purposes,
							posing a reputational risk for us or increasing our risk level;
						</li>
						<li>
							Inability to verify your identity, source of funds, or other
							provided information;
						</li>
						<li>
							Reasonable belief that your use of our Services or VivatPay System
							may compromise, corrupt, or adversely affect our Services or
							VivatPay System;
						</li>
						<li>
							Material changes in your business activities or transaction
							volume;
						</li>
						<li>Bankruptcy, insolvency, liquidation procedure on your part;</li>
						<li>Change of Control if you did not inform us about this.</li>
					</ul>

					<p>
						Where feasible, we will promptly notify you of any measures taken
						under this clause and explain any necessary actions to restore full
						access to our Services and/or VivatPay System. However,
						communication may be restricted if prohibited by law or poses a
						security risk.
					</p>

					<p>
						In instances of suspected money laundering, terrorist financing, or
						other criminal activity, or if necessary to comply with legal
						obligations, we reserve the right to immediately suspend access to
						VivatPay System and our Services without notice pending further
						investigation.
					</p>

					<p>
						Suspension of your access to VivatPay System and our Services will
						be lifted if the circumstances leading to the suspension no longer
						exist.
					</p>

					<p>
						The Company shall not be liable for any losses resulting from the
						suspension of access to VivatPay System and our Services, or for any
						other restrictions imposed by these Terms of Use or under the
						Agreement.
					</p>

					<h2>9. Personal Data</h2>
					<p>
						When processing personal data, the Company is guided by Regulation
						(EU) 2016/679 of the European Parliament and of the Council of 27
						April 2016 on the protection of natural persons with regard to the
						processing of personal data and on the free movement of such data,
						and repealing Directive 95/46/EC (the “General Data Protection
						Regulation”), by the Law of the Republic of Lithuania on the Legal
						Protection of Personal Data, and by the requirements laid down in
						other legal acts governing the processing and protection of personal
						data.
					</p>
					<p>
						The Company’s <Link to='/privacy-policy'> Privacy Policy</Link> is
						available at:{' '}
						<Link to='/privacy-policy'>https://vivatpay.eu/policy </Link>
						By submitting an <Link to='/register'> Authorisation Form</Link> and
						registration, the Client also acknowledges the{' '}
						<Link to='/privacy-policy'> Privacy Policy</Link> by ticking the
						checkbox.
					</p>

					<h2>10. Intellectual Property Rights</h2>
					<p>
						The Company is the sole owner of all Intellectual Property Rights of
						VivatPay System and the Company does not transfer any of its rights
						neither under the Agreement nor hereunder. The Company only provides
						the User with a non-exclusive right to use the Intellectual Property
						Rights of its VivatPay System in the frame of provision of payment
						initiation services by the Company.
					</p>

					<h2>11. Disclaimer</h2>
					<p>
						To the fullest extent permitted by law, VivatPay System and/or
						services are provided "as is" and “as available”. Neither the
						Company nor any of the Company’s representatives makes any other
						warranties, express or implied, statutory or otherwise, including
						but not limited to warranties of merchantability, title, fitness for
						a particular purpose, or noninfringement. The Company does not
						warrant that the User's use of VivatPay System and/or services will
						be secure, uninterrupted, free from viruses or other malicious code,
						or error-free. The Company shall not be liable for delays,
						interruptions, service failures, or other problems inherent in the
						use of the internet and electronic communications, third-party
						platforms, or other systems outside the Company's reasonable
						control.
					</p>

					<h2>12. Cookies</h2>
					<p>
						We use cookies to improve our Website and services and enhance your
						browsing experience of our Website. These cookies also help us
						gather data for analytical and advertising purposes. We understand
						and respect your privacy preferences, allowing you to opt-out of
						certain types of cookies. Rest assured, your privacy is important to
						us, and we strive to provide you with the best possible browsing
						experience while respecting your choices regarding cookie settings.
						Therefore, you can manage the cookie preferences through your
						browser settings. You may choose to accept, reject, or delete
						cookies. However, please note that disabling certain cookies may
						affect the functionality and performance of our Website for you.
					</p>

					<h2>13. Contact Information</h2>
					<p>
						You can contact the Company via e-mail{' '}
						<a href='mailto:vivatpay@gmail.com'>vivatpay@gmail.com</a>, or hotline{' '}
						<a href='tel:+3706921083'>+3706921083</a>, or mail Kareivių str.
						11B, LT-09133, Vilnius, the Republic of Lithuania.
					</p>
					<p>
						Working days and hours: Monday - Friday (except for holidays and
						non-working days according to the legislation of the Republic of
						Lithuania) from 09:00 to 18:00.
					</p>
					<p>Break hours: from 13:00 to 14:00.</p>
					<p>
						Days off: Saturday - Sunday, public holidays, and non-working days
						in accordance with the laws of the Republic of Lithuania.
					</p>

					<h2>14. Final Provisions</h2>
					<p>
						These Terms of Use shall be effective as of 18 April 2024 and are
						valid until the date of their revocation or publication of a new
						version on the Website by the Company.
					</p>
					<p>
						The Company has the right to unilaterally change any clauses and
						provisions of these Terms of Use without separate notice by
						announcing it on the Website.
					</p>
					<p>
						For Users with whom the Company has entered into an Agreement, the
						terms of the agreement shall prevail, regardless of changes to the
						Terms of Use on the Company’s website.
					</p>
					<p>
						All disputes between the Company and the Client shall be settled in
						good faith by negotiation, and in case of inability to reach an
						agreement – in a competent court at the address of the Company’s
						location in accordance with the material law of the Republic of
						Lithuania unless otherwise is stated in the executed Agreement
						between Company and User.
					</p>
					<p>
						All additional notices and information if any related to the use of
						the Website and Services will be sent to the Client by electronic
						means, to the e-mail address specified in the{' '}
						<Link to='/register'> Authorisation Form</Link>.
					</p>
					<p>
						In connection with the protection of the Company’s violated rights,
						the Company may rely on both these Terms of Use and the Agreement as
						well as other remedies available under applicable laws.
					</p>
				</div>
			</div>
		</section>
	)
}
