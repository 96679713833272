import React from 'react'
import LoanCalculator from '../../components/LoanCalculator.js/LoanCalculator'
import './Borrow.css'
export const HowToBorrow = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='page-container'>
						<div className='borrow-left'>
							<div className='page-title'>How to borrow?</div>
							<div className='borrow-text'>
								Online microcredit is an ideal way to quickly solve a sudden
								need for financial resources. You can get a microloan from
								VivatPay online from your home or workplace. There is no need to
								come to the office. Just visit our site through your phone,
								laptop or tablet
							</div>

							<div className='borrow-card-flex'>
								<div className='borrow-card'>
									<div className='card-circle'>1</div>
									<div className='card-description'>
										<p className='card-title'>
											Choose the loan amount and term
										</p>
										<p className='card-text'>
											You can leave a request by filling out the form
										</p>
									</div>
								</div>
								<div className='borrow-card'>
									<div className='card-circle'>2</div>
									<div className='card-description'>
										<p className='card-title'>Wait for the decision</p>
										<p className='card-text'>
											The decision is made in just 1 minute
										</p>
									</div>
								</div>
								<div className='borrow-card'>
									<div className='card-circle'>3</div>
									<div className='card-description'>
										<p className='card-title'>Receive money</p>
										<p className='card-text'>
											Get money to your account within 15 minutes
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className='borrow-right'>
							<LoanCalculator width={300} />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
