import React from 'react'
import { Link } from 'react-router-dom'
import './Policy.css'

export const Policy = () => {
	return (
		<>
			<section className='page-background'>
				<div className='content-container'>
					<div className='privacy-policy'>
						<h1>Purpose and Scope of Privacy Policy</h1>
						<p>
							Safeguarding your personal information is a top priority of
							VivatPay, UAB (hereinafter referred to as the “Company”, “we”, or
							“us”). We are dedicated to protecting your privacy and ensuring
							that your personal data is handled in a transparent and lawful
							manner, in compliance with the regulations of the European Union
							(General Data Protection Regulation 2016/679) and the laws of the
							Republic of Lithuania.
						</p>
						<p>This Privacy Policy applies to:</p>
						<ul>
							<li>Your use of our Website;</li>
							<li>Your use of our Payment Initiation Services; and</li>
							<li>Your use of our VivatPay System;</li>
						</ul>
						<p>
							In order to offer our Payment Initiation Services to you, we
							collect personal data, which includes information gathered during
							any necessary application including, but not limited to, the
							<Link to='/register'> Authorisation Form</Link> and/or
							registration process to access our Services.
						</p>
						<p>
							In this Privacy Policy, we outline the types of personal data we
							gather and process concerning the Users and/or their
							representatives, if any, who submit a Cooperation Request and
							utilize our Payment Initiation Services, or who have subscribed to
							receive advertising or information from our Company.
						</p>
						<p>
							Please note that certain terms used in this Privacy Policy have
							defined meanings. These terms are capitalized and these
							definitions as well as additional information can be found in our
							<Link to='/terms-and-conditions'> Terms & Conditions</Link>.
							Please also note that additional information might also be
							provided in our internal policies, contracts, and other documents
							provided by us to you before entering into mutual cooperation.
						</p>
						<p>
							Please thoroughly review this Privacy Policy to comprehend our
							procedures concerning your personal data and how we intend to
							handle it.
						</p>
						<p>
							By agreeing to this Policy, you explicitly authorize us to
							collect, process, utilize, and disclose your personal information
							as outlined herein.
						</p>
						<p>
							Please note that we process your personal data only in the context
							of providing you with Payment Initiation Services. Such processing
							of your personal data is based only on obtaining your consent, our
							mutual contractual arrangements, and our legal obligations in
							accordance with General Data Protection Regulation 2016/679, the
							Payment Law of the Republic of Lithuania, and/or other applicable
							laws of the Republic of Lithuania. You can withdraw your consent
							at any time by contacting us via the email indicated in section 13
							of <Link to='/terms-and-conditions'> Terms & Conditions</Link>.
						</p>

						<h2>The Types of Data We Collect</h2>
						<p>
							When you use our Website and apply for cooperation (submit a
							Cooperation Request), you must provide the following data:
						</p>
						<ul>
							<li>Your company name;</li>
							<li>Contact person (full name);</li>
							<li>Email address;</li>
							<li>Contact telephone number.</li>
						</ul>
						<p>
							Please take note that before entering into cooperation and
							execution of agreements with you for the provision of Payment
							Initiation Services, we may request you to provide us with
							additional information in accordance with this Privacy Policy, our
							internal policies, contracts, and/or other documents provided by
							us to you before entering into mutual cooperation. All personal
							data, if any, provided under the mentioned documents are processed
							in accordance with this Privacy Policy.
						</p>
						<p>
							In respect of personal data, we collect and process the following
							categories of personal data:
						</p>
						<ul>
							<li>
								<strong>Personal data:</strong> full name, date of birth,
								personal identification documents, residential address, contact
								details (telephone number, email address, correspondence
								address).
							</li>
							<li>
								<strong>Financial data:</strong> bank account details and
								transaction details which you are going to conduct through
								VivatPay System. In order to conduct Payment Initiation
								Services, we may handle certain personal data of your customers.
								Please take note that in such a case you are responsible before
								your customers for their data processing and storage of their
								personal data as you are acting as the personal data controller
								of your customers' data. Therefore, you must furnish your
								customers with exhaustive details regarding the processing of
								their personal data and the recipients of such data.
							</li>
							<li>
								<strong>Communication data:</strong> information gathered
								through various communication and technical channels such as
								data contained in messages, emails, etc.
							</li>
							<li>
								<strong>Device data:</strong> this pertains to details about
								your computer or handheld device, such as the internet protocol
								(IP) address, computer operating system, browser, browser
								version, plug-ins, computer time zone settings, mobile device
								type, unique device identifier, mobile operating system, and
								version.
							</li>
						</ul>
						<p>
							We are authorized to gather and utilize this data as it is
							essential for us to establish a contractual agreement with you and
							deliver our services to you within that agreement. It is also
							necessary for us to adhere to our legal and regulatory
							commitments. Failure to collect and process this information will
							prevent us from offering our services to you.
						</p>

						<h2>Data Received from Third Parties</h2>
						<p>
							The majority of the data in our possession concerning you will
							either be provided directly by you or will be gathered during your
							utilization of our Website and VivatPay System. Nevertheless, we
							might also receive personal details and/or information from other
							sources, such as:
						</p>
						<ul>
							<li>Business partners;</li>
							<li>Financial institutions;</li>
							<li>
								Fraud prevention agencies and identity verification services;
							</li>
							<li>Referral partners;</li>
							<li>Other public sources.</li>
						</ul>

						<h2>With Whom We May Share Your Personal Data</h2>
						<p>
							We may share your personal data to ensure the provision of our
							Payment Initiation Services to you with our partners and/or other
							parties involved in the performance of the Payment Initiation
							Services through the VivatPay System or in other cases, where
							necessary and/or required by law. We ensure that personal data is
							not disclosed beyond what is essential for the provision of
							Payment Initiation Services. We will not sell, distribute, or
							lease your personal data to other third parties, except for
							external service providers such as: banking partners, technical
							service providers, payment card issuers, payment service
							providers, and/or other financial institutions.
						</p>
						<p>
							Except for disclosing your personal data in the frame of provision
							of Payment Initiation Services to you, we may also disclose your
							personal data to the following recipients:
						</p>
						<ul>
							<li>Law enforcement authorities;</li>
							<li>Courts;</li>
							<li>Our affiliates;</li>
							<li>Our auditors;</li>
							<li>Other contractors involved in the provision of services.</li>
						</ul>

						<h2>Transfer of Personal Data to Third Countries</h2>
						<p>
							We are committed to safeguarding your personal data and ensuring
							that it is handled securely and in compliance with our Privacy
							Policy. Your Personal Data will only be transferred to
							organizations or countries with adequate controls in place to
							protect your information, as outlined in this Policy. In order to
							provide our Payment Initiation Services, your personal data may
							need to be shared with companies located outside of the EU/EEA
							countries. When transferring data to third countries, we uphold
							applicable laws to ensure a sufficient level of data protection.
							We comply with the standard requirements of General Data
							Protection Regulation 2016/679 to maintain adequate safeguards for
							the international transfer of your data and oblige our affiliates,
							service providers, and/or other partners to comply with this
							General Data Protection Regulation 2016/679 as well.
						</p>

						<h2>How We Store Your Personal Data</h2>
						<p>
							Security is a top priority in the provision of our Payment
							Initiation Services. We have established various organizational
							and technical measures to protect your personal data from
							accidental or unlawful destruction, alteration, disclosure, and
							any other unauthorized processing. While we make every effort to
							use commercially acceptable methods to safeguard your personal
							data, we cannot guarantee its complete security.
						</p>

						<h2>What are Your Rights</h2>
						<p>
							You may have the following rights with regard to your personal
							data that is prescribed in General Data Protection Regulation
							2016/679:
						</p>
						<ul>
							<li>
								<strong>Accessing and updating information:</strong> If you find
								that any of the information we have is incorrect or incomplete,
								you have the right to have it rectified. You can request a copy
								of the information we hold about you, correct it, and send us
								updated information or you can ask us to correct any
								inaccuracies that you are unable to update yourself. We will
								also inform our partners who have shared your data about updates
								in your data. Also, you have the right at any time to request
								and receive your personal data that we are processing.
							</li>
							<li>
								<strong>Right to object:</strong> You have the right to object
								to the processing of your personal data that is based on
								legitimate interests or the performance of tasks in the public
								interest of official authority, unless we have strong
								justifiable reasons to proceed with processing your data or if
								the processing is necessary for the establishment, exercise, or
								defense of legal claims or for other legitimate reasons. You
								have the right to object to the processing of your personal data
								at any time, we utilize your personal data for sending
								advertising or information from our Company.
							</li>
							<li>
								<strong>The right to be informed:</strong> You have the right to
								request and to be provided with clear and transparent
								information about the processing of your data.
							</li>
							<li>
								<strong>The right of access:</strong> You can contact us via the
								email indicated in section 13 of the{' '}
								<Link to='/terms-and-conditions'> Terms & Conditions</Link> and
								request us to provide you with access to your personal data. We
								will grant you access to your personal data along with the
								following details: the processing purposes, the types of
								personal data we collected, the partners with whom we shared
								your personal data, the timeframe for retaining or expected
								retention period of the data, the origin of the data if we
								received from third parties as indicated in Section 4 of this
								Policy.
							</li>
							<li>
								<strong>The right to restrict processing:</strong> You have the
								right to request us not to process your personal data in the
								following situations:
								<ul>
									<li>If the accuracy of the data is being disputed;</li>
									<li>
										If the data is no longer needed for its original purpose but
										you require it to establish, exercise, or defend a legal
										claim;
									</li>
									<li>
										If the data has been processed unlawfully and you prefer a
										restriction on processing instead of deletion;
									</li>
									<li>
										If you have objected to the processing of your data and we
										are evaluating if there are overriding legitimate grounds to
										continue processing.
									</li>
								</ul>
							</li>
							<li>
								<strong>The right to be forgotten:</strong> You have the right
								to request to delete your personal data, if:
								<ul>
									<li>
										There is no longer an original purpose for which we
										collected your personal data, and/or a legal basis or
										legitimate reason for us to further process your personal
										data;
									</li>
									<li>The data has been unlawfully processed;</li>
									<li>
										It is necessary to comply with General Data Protection
										Regulation 2016/679 and the laws of the Republic of
										Lithuania.
									</li>
								</ul>
								Please be assured that we will take all reasonable steps to
								ensure that your personal data is deleted.
							</li>
						</ul>

						<h2>Complaints</h2>
						<p>
							If you have any complaints, comments, or questions about this
							Policy, you can contact us via e-mail indicated in section 13 of
							Terms & Conditions. If you believe that your rights under the
							General Data Protection Regulation 2016/679 have been violated,
							you have the right to file a complaint with the appropriate
							supervisory authority in Lithuania (State Data Protection
							Inspectorate).
						</p>

						<h2>Privacy Policy Review</h2>
						<p>
							We may change or revise this Privacy Policy at any time. Any
							updates or amendments to the Privacy Policy will be effective upon
							publication on this page. It is recommended that you periodically
							review the Privacy Policy to ensure that you are comfortable with
							the current version. The last updated date will be displayed at
							the end of this Policy.
						</p>
						<p>
							<strong>Last updated:</strong> 18 April 2024.
						</p>
					</div>
				</div>
			</section>
		</>
	)
}
