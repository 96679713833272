/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import './Register.css'

export const Register = () => {
	const countryCodes = [
		{ code: '+370', country: 'Lithuania' },
		{ code: '+48', country: 'Poland' },
		{ code: '+33', country: 'France' },
		{ code: '+49', country: 'Germany' },
		{ code: '+34', country: 'Spain' },
		{ code: '+40', country: 'Romania' },
		{ code: '+420', country: 'Czech Republic' },
	]
	return (
		<div className='sign-up-container'>
			<h1 className='sign-up-title'>Sign up</h1>
			<div className='input-container'>
				<select className='country-select'>
					{countryCodes.map((item, index) => (
						<option key={index} value={item.code}>
							{item.code} {item.country}
						</option>
					))}
				</select>
				<input type='text' className='phone-input' placeholder='Phone number' />
			</div>
			<p className='terms-text'>
				You acknowledge that you agree with to the{' '}
				<Link to='/terms-and-conditions'> Terms & Conditions</Link>,{' '}
				<Link to='/privacy-policy'> Data privacy policy</Link>. You acknowledge
				that VivatPay (VivatPay, UAB) has been appointed by you as your
				authorized representative to obtain your credit information from credit
				bureaus for the purpose of assessing your creditworthiness
			</p>
			<button className='continue-button'>Continue</button>
			<a href='#' className='sign-in-link'>
				Sign in
			</a>
		</div>
	)
}
