import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material'
import React from 'react'

export const Faq = ({ faqs, bgc }) => {
	return (
		<div className='faq-item'>
			{faqs.map((faq, index) => (
				<Accordion key={index} sx={{ mb: 3 }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel${index}-content`}
						id={`panel${index}-header`}
						sx={{
							height: '80px',
							background: bgc ? 'white' : 'rgba(247, 247, 250, 1)',
						}}
					>
						<Typography>{faq.question}</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{ background: bgc ? 'white' : 'rgba(247, 247, 250, 1)' }}
					>
						<Typography>{faq.answer}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	)
}
