import React from 'react'
import { Link } from 'react-router-dom'
import '../../pages/Home/Home.css'

export const BtnApply = () => {
	return (
		<div className='block-btn'>
			<Link to='/register' className='btn'>
				Apply for a loan
			</Link>
		</div>
	)
}
