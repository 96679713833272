/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Link } from 'react-router-dom'
import { BtnApply } from '../../components/BtnApply/BtnApply'
import { Faq } from '../../components/FaqAccordion/Faq'
import { faqDataInHome } from '../../components/FaqAccordion/faqData'
import LoanCalculator from '../../components/LoanCalculator.js/LoanCalculator'
import card1 from '../../images/card1.svg'
import card2 from '../../images/card2.svg'
import card3 from '../../images/card3.svg'
import people from '../../images/photoframe.svg'
import Card from './Card'
import { Description } from './Description'
import EasyStep from './EasyStep'
import './Home.css'
import { LendingServices } from './LendingServices'
import { ReviewItem } from './ReviewItem'
const Home = () => {
	return (
		<div className='home'>
			<div className='home-frame-container'>
				<section className='home-frame'>
					<h1>
						Get first loan online <br /> in just <span>15 minutes!</span>
					</h1>
					{/* <img src={card} alt='Card' className='cardFrame' /> */}
					<div className='cardFrame'>
						<LoanCalculator />
					</div>
					<img src={people} alt='people' className='cardPeople' />
				</section>
			</div>

			<section className='cards'>
				<div className='block-title'>VivatPay advantages</div>
				<div className='card-block'>
					<Card
						imgSrc={card1}
						title='Minimum overpayment that makes our product the best'
						description='The ability to quickly improve credit history'
					/>
					<Card
						imgSrc={card2}
						title='VivatPay lender with a new approach'
						description='We guarantee confidentiality and protection of your data'
					/>
					<Card
						imgSrc={card3}
						title='Simple and quick solution'
						description='Without leaving home we transfer money instantly to your account in just 5 minutes'
					/>
				</div>

				<div style={{ maxWidth: '300px', margin: '0 auto' }}>
					<BtnApply />
				</div>
			</section>

			<section className='easy-block'>
				<div className='block-title'>Easy steps to get a loan</div>

				<div className='flex-block-card'>
					<EasyStep
						stepNumber='1'
						title='Choose the loan amount and term'
						description='You can leave a request by filling out the form'
					/>
					<EasyStep
						stepNumber='2'
						title='Wait for the decision'
						description='The decision is made in just 1 minute'
					/>
					<EasyStep
						stepNumber='3'
						title='Receive your funds'
						description='The funds will be transferred to your account immediately'
					/>
				</div>
				<div style={{ maxWidth: '300px', margin: '0 auto' }}>
					<BtnApply />
				</div>
			</section>

			<Faq faqs={faqDataInHome} />
			<div className='see-more'>
				<Link to='/faq'>See more</Link>
			</div>

			<ReviewItem />
			<Description />
			{/* <LendingServices /> */}
		</div>
	)
}

export default Home
