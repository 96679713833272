import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'
import { About } from './pages/About/About'
import { Cancellation } from './pages/Cancellation/Cancellation'
import { ContactUs } from './pages/ContactUs/ContactUs'
import { FaqPages } from './pages/FAQ/FaqPages'
import Home from './pages/Home/Home'
import { HowToBorrow } from './pages/HowToBorrow/HowToBorrow'
import { HowToPostpone } from './pages/HowToPostpone/HowToPostpone'
import { Policy } from './pages/policy/Policy'
import { Register } from './pages/Register/Register'
import ScrollToTop from './ScrollToTop'
import { Term } from './pages/term&conditions/Term'

function App() {
	return (
		<Router>
			<ScrollToTop />
			<div className='container'>
				<Header />
				<main className='main-content'>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/register' element={<Register />} />
						<Route path='/borrow' element={<HowToBorrow />} />
						<Route path='/postpone' element={<HowToPostpone />} />
						<Route path='/faq' element={<FaqPages />} />
						<Route path='/about' element={<About />} />
						<Route path='/contact' element={<ContactUs />} />
						<Route path='/cancellation' element={<Cancellation />} />
						<Route path='/privacy-policy' element={<Policy />} />
						<Route path='/terms-and-conditions' element={<Term />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</Router>
	)
}

export default App
